































import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { AcademicYearModel, DashboardItemsModel, DashboardModel } from "@/core/webapi";
import { AcademicYearSelectCpt } from "@/core/components";
import { ApiService, NotificationProvider } from "@/core/services";
import { ApplicationStatus } from "@/core/constants";
import { DashboardCpt } from "./components";

@Component({
  components: {
    DashboardCpt,
    AcademicYearSelectCpt,
  },
})
export default class DashboardView extends AppVue {
  selectedAcademicYear = new AcademicYearModel({
    id: this.currentAcademicYear,
  });
  academicYears: AcademicYearModel[] = [];
  model = new DashboardModel();
  trainings: DashboardItemsModel[] = [];

  async loadLookups() {
    this.isLoading = true;
    try {
      const resp = await ApiService.report().getDashboardLookups();
      this.academicYears = resp.data.academicYears as AcademicYearModel[];
    } finally {
      this.isLoading = false;
    }
  }

  async loadDashboard() {
    NotificationProvider.loadingStart("Loading dashboard");
    try {
      const resp = await ApiService.report().getDashboard(this.selectedAcademicYear.id);
      this.model = resp.data;
      this.trainings = this.model.applicants.filter(
        // eslint-disable-next-line
        item =>
          item.groupId === ApplicationStatus.AwaitingExperientialTraining ||
          item.groupId === ApplicationStatus.AwaitingBlackboardTraining,
      );
    } finally {
      NotificationProvider.loadingStop();
    }
  }

  async created() {
    await this.loadLookups();
    await this.loadDashboard();
  }

  getColor(item: DashboardItemsModel) {
    if (item.groupId === ApplicationStatus.Declined) {
      return "red";
    }

    if (item.groupId === ApplicationStatus.Trained) {
      return "green";
    }

    return "";
  }
}
