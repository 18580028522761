















































import AppVue from "@/AppVue.vue";
import { ButtonCpt } from "@/core/components/common";
import { DashboardItemsModel } from "@/core/webapi";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    ButtonCpt,
  },
})
export default class DashboardCpt extends AppVue {
  @Prop() label: string;
  @Prop() items: DashboardItemsModel[];
  @Prop({ default: true }) showTotal: boolean;
  @Prop({ default: true }) showDetail: boolean;
  // eslint-disable-next-line
  @Prop({ default: null }) getColor: (item: DashboardItemsModel) => string;

  get internalItems() {
    return this.showDetail ? this.items : [];
  }

  get total() {
    return this.items ? this.items.reduce((a, b) => a + b.count || 0, 0) : 0;
  }

  getColorInternal(item: DashboardItemsModel) {
    if (this.getColor) {
      return this.getColor(item);
    }

    return "";
  }
}
